import React from "react";
import propTypes from "prop-types";
import { Grid, Checkbox } from "@mui/material";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

const CheckboxField = ({ checked, label, onChange, disabled, error, ...rest }) => {
  return (
    <Grid item xs={12} my={2}>
      <MKBox display="flex" alignItems="center">
        <Checkbox
          checked={checked}
          onChange={onChange}
          disabled={disabled}
          color="primary"
          className={error ? "Mui-error" : ""}
          {...rest}
        />
        <MKTypography
          variant="button"
          color={error ? "error" : "text"}
          fontWeight="regular"
          ml={1}
          sx={{
            cursor: disabled ? "not-allowed" : "pointer",
            userSelect: "none",
          }}
          onClick={!disabled ? onChange : undefined} // Évite les clics si désactivé
        >
          {label}
        </MKTypography>
      </MKBox>
    </Grid>
  );
};

// Valeurs par défaut pour les props
CheckboxField.defaultProps = {
  checked: false,
  label: "",
  disabled: false,
  error: false,
  onChange: () => {}, // Fonction par défaut si `onChange` n'est pas fournie
};

// Définition des types de props
CheckboxField.propTypes = {
  checked: propTypes.bool,
  label: propTypes.string, // `label` doit être une chaîne de caractères
  disabled: propTypes.bool,
  onChange: propTypes.func, // Permet la gestion des modifications
  error: propTypes.bool,
};

export default CheckboxField;
