import { Grid, MenuItem } from "@mui/material";
import CheckboxField from "components/CheckboxField";
import DefaultHeader from "components/DefaultHeader";
import InputField from "components/InputField";
import InputNpaField from "components/InputNpaField";
import InputPhoneField from "components/InputPhoneField";
import LoadingButton from "components/LoadingButton";
import MKTypography from "components/MKTypography";
import SectionTitle from "components/SectionTitle";
import SuspenseSpin from "components/SuspenseSpin";
import config from "config";
import { routes } from "data/data";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import { validatePhoneNumber } from "lib";
import {
  callbackAfterRecaptcha,
  encodeFormData,
  getImage,
  upperFirstLettre,
  validateEmail,
} from "lib";
import { useRecaptcha } from "lib/useRecaptcha";
import { useCallback, useState } from "react";
import { Trans, useTranslation } from "react-i18next";

const bgImage = getImage("pexels-thirdman-8926648.webp", true, 1800);

const activities = ["aideAuxDevois", "anglais", "yogaOuSophrologie", "piano"];

const activitySuffixes = {
  piano: " (avec frais supplémentaires)",
  default: " (inclus dans le prix)",
};

const Sondage = () => {
  const { t } = useTranslation();
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    nombreEnfants: "", // Si oui
    ecole: "", // Nom de l’école et ville
    ecoleVille: "", // Localité de l’école
    annee: "", // 2025, etc
    vacancesScolaires: "",
    niveauScolaire: [], // 1P à 6P
    lundi: [],
    mardi: [],
    mercredi: [],
    jeudi: [],
    vendredi: [],
    anglais: "", // Oui / Non
    piano: "", // Oui / Non
    aideAuxDevois: "", // Oui / Non
    yogaOuSophrologie: "", // Oui / Non
    nom: "",
    email: "",
    telephone: "",
    provenance: "",
    remarques: "",
  });

  useRecaptcha();

  const handleChange = useCallback((e) => {
    const { name, value } = e.target;

    setFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  }, []);

  const handleNiveauScolaireChange = useCallback((niveau, cle) => {
    setFormData((prev) => {
      const updatedNiveaux = prev[cle] || [];

      // Ajouter ou retirer le niveau sélectionné
      const newNiveaux = updatedNiveaux.includes(niveau)
        ? updatedNiveaux.filter((n) => n !== niveau) // Retirer
        : [...updatedNiveaux, niveau]; // Ajouter

      return {
        ...prev,
        [cle]: newNiveaux,
      };
    });
  }, []);

  const handleServiceChange = useCallback((day, service) => {
    setFormData((prev) => {
      const servicesForDay = prev[day] || [];

      // Si le service est déjà sélectionné, on le retire, sinon on l'ajoute
      const updatedServicesForDay = servicesForDay.includes(service)
        ? servicesForDay.filter((s) => s !== service) // Retirer le service
        : [...servicesForDay, service]; // Ajouter le service

      return {
        ...prev,
        [day]: updatedServicesForDay,
      };
    });
  }, []);

  const validate = useCallback(() => {
    let tempErrors = {};

    if (!formData.nombreEnfants)
      tempErrors.nombreEnfants = "Veuillez indiquer le nombre d’enfants.";
    if (!formData.ecole) tempErrors.ecole = "Nom de l’école requis.";
    if (!formData.ecoleVille) tempErrors.ecoleVille = "Ville de l’école requis.";
    if (!formData.vacancesScolaires)
      tempErrors.vacancesScolaires = "Service de garde pendant les vacances scolaires requis.";
    if (!formData.niveauScolaire || formData.niveauScolaire.length === 0)
      tempErrors.niveauScolaire = "Niveau scolaire requis.";
    if (formData.email && !validateEmail(formData.email)) tempErrors.email = "Email valide requis";
    activities.forEach((activity) => {
      if (!formData[activity]) {
        tempErrors[activity] = `${upperFirstLettre(activity)} requis.`;
      }
    });
    validatePhoneNumber(formData.telephone, tempErrors, "telephone", false);

    console.log("tempErrors>", tempErrors);
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  }, [formData]);

  const sendForm = () => {
    if (validate()) {
      console.log(">> sendForm", formData);

      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encodeFormData({ "form-name": config.sondageFormName, ...formData }),
      })
        .then(() => {
          window.location.href = "/sondage-confirmation";
        })
        .catch((error) => {
          console.error("Erreur de soumission : " + error);
          setErrors({ sendForm: error });
        });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      // Si toutes les validations passent, vous pouvez envoyer le formulaire ici
      console.log("Formulaire validé, prêt pour l'envoi !");
      callbackAfterRecaptcha(() => sendForm());
    }
  };

  // console.log("formData.nombreEnfants", formData.nombreEnfants, (formData.nombreEnfants === "1"));

  return (
    <SuspenseSpin>
      <DefaultNavbar routes={routes} source="name" sticky />
      <DefaultHeader
        title="Votre ville, votre UAPE privée : Participez à notre sondage !"
        bgImage={bgImage}
        description={t("articles.sondage.summary")}
        minHeight="35rem"
      >
        <form
          name={config.sondageFormName}
          method="POST"
          data-netlify="true"
          onSubmit={(e) => e.preventDefault()}
        >
          <input type="hidden" name="form-name" value={config.sondageFormName} />

          <MKTypography variant="body2" color="text" mt={3}>
            <Trans i18nKey="description" />
          </MKTypography>
          <SectionTitle title="Vos besoins" />

          <InputNpaField
            label="Nombre d'enfants allant à l'école en 2025/2026 ?"
            name="nombreEnfants"
            // type="number"
            value={formData.nombreEnfants}
            onChange={handleChange}
            error={!!errors.nombreEnfants}
            helperText={errors.nombreEnfants}
          />

          <InputField
            label={`À quelle école ${
              formData.nombreEnfants === "1" ? "ira votre enfant" : "iront vos enfants"
            } ?`}
            name="ecole"
            value={formData.ecole}
            onChange={handleChange}
            error={!!errors.ecole}
            helperText={errors.ecole}
          />
          <InputField
            label="Dans quelle ville se trouvera l'école ?"
            name="ecoleVille"
            value={formData.ecoleVille}
            onChange={handleChange}
            error={!!errors.ecoleVille}
            helperText={errors.ecoleVille}
          />

          <InputField
            label={`Vos autres enfants iront à l'école en quelle année ?`}
            name="annee"
            select
            value={formData.annee}
            onChange={handleChange}
            error={!!errors.annee}
            helperText={errors.annee}
          >
            <MenuItem value="">
              <em>Choisissez une année</em>
            </MenuItem>
            <MenuItem value="2026">2026/2027</MenuItem>
            <MenuItem value="2027">2027/2028</MenuItem>
          </InputField>

          <MKTypography variant="h5">
            Niveau scolaire de {formData.nombreEnfants === "1" ? "votre enfant" : "vos enfants"} en
            2025/2026
          </MKTypography>
          {["1P", "2P", "3P", "4P", "5P", "6P", "7P", "8P"].map((niveau) => (
            <CheckboxField
              key={niveau}
              label={niveau}
              name={`niveauScolaire-${niveau}`} // Un nom unique pour chaque case
              checked={formData.niveauScolaire?.includes(niveau)}
              onChange={() => handleNiveauScolaireChange(niveau, "niveauScolaire")}
              error={!!errors.niveauScolaire}
            />
          ))}
          {errors.niveauScolaire && (
            <MKTypography variant="body2" color="error" fontSize="0.8rem" mb={2}>
              {errors.niveauScolaire}
            </MKTypography>
          )}

          {["lundi", "mardi", "mercredi", "jeudi", "vendredi"].map((day) => (
            <div key={day}>
              <h4>{`Service d’accueil souhaité pour ${upperFirstLettre(day)}`}</h4>
              {[
                "Matin avant école dès 6h30",
                "Midi avec repas",
                "Après-midi jusqu’à 16h",
                "Fin de journée de 16h à 18h30",
              ].map((service) => (
                <CheckboxField
                  key={`${day}-${service}`}
                  label={service}
                  name={`${day}-${service}`} // Ajout d'un nom unique pour chaque case
                  checked={formData[day]?.includes(service)}
                  onChange={() => handleServiceChange(day, service)}
                />
              ))}
            </div>
          ))}

          <MKTypography variant="h5">
            Aurez-vous besoin d’un service de garde pendant les vacances scolaires ?
          </MKTypography>
          {/* {["Oui", "Non"].map((ouiNon) => (
            <CheckboxField
              key={ouiNon}
              label={ouiNon}
              name={`vacancesScolaires-${ouiNon}`} // Un nom unique pour chaque case
              checked={formData.vacancesScolaires?.includes(ouiNon)}
              onChange={() => handleNiveauScolaireChange(ouiNon, "niveauScolaire")}
              error={!!errors.vacancesScolaires}
            />
          ))}
          {errors.vacancesScolaires && (
            <MKTypography variant="body2" color="error" fontSize="0.8rem" mb={2}>
              {errors.vacancesScolaires}
            </MKTypography>
          )} */}
          <InputField
            // label="Avez-vous besoin de service de garde pendant les vacances scolaires ?"
            name="vacancesScolaires"
            select
            value={formData.vacancesScolaires}
            onChange={handleChange}
            error={!!errors.vacancesScolaires}
            helperText={errors.vacancesScolaires}
          >
            <MenuItem value="Oui">Oui</MenuItem>
            <MenuItem value="Non">Non</MenuItem>
          </InputField>

          <SectionTitle title="Activités proposées" />
          {activities.map((activity) => (
            <InputField
              key={activity}
              label={
                upperFirstLettre(activity) +
                (activitySuffixes[activity] || activitySuffixes.default)
              }
              name={activity}
              select
              value={formData[activity]}
              onChange={handleChange}
              error={!!errors?.[activity]}
              helperText={errors?.[activity]}
            >
              <MenuItem value="Oui">Oui</MenuItem>
              <MenuItem value="Non">Non</MenuItem>
            </InputField>
          ))}

          <SectionTitle title="Vos coordonées (facultatif)" />
          <MKTypography variant="body2" color="text" mb={4}>
          En répondant à notre sondage, vous serez <b>prioritaire</b> lors des inscriptions.
          </MKTypography>
          <InputField
            label="Nom"
            name="nom"
            value={formData.nom}
            onChange={handleChange}
            error={!!errors.nom}
            helperText={errors.nom}
          />
          <InputField
            label="Email"
            name="email"
            type="email"
            value={formData.email}
            onChange={handleChange}
            error={!!errors.email}
            helperText={errors.email}
          />
          <InputPhoneField
            label="Téléphone"
            name="telephone"
            value={formData.telephone}
            onChange={handleChange}
            error={!!errors.telephone}
            helperText={errors.telephone}
          />
          <InputField
            label={`Par quel moyen avez-vous connu notre UAPE ?`}
            name="provenance"
            select
            value={formData.provenance}
            onChange={handleChange}
            error={!!errors.provenance}
            helperText={errors.provenance}
          >
            <MenuItem value="Garderie">Garderie aux petits pas</MenuItem>
            <MenuItem value="Google">Google</MenuItem>
            <MenuItem value="Pub">Publicité</MenuItem>
            <MenuItem value="Autre">Autre</MenuItem>
          </InputField>

          <SectionTitle title="Remarques" />
          <InputField
            multiline
            rows={4}
            name="remarques"
            value={formData.remarques}
            onChange={handleChange}
            error={!!errors.remarques}
            helperText={errors.remarques}
          />

          <Grid container item justifyContent="center" xs={12} mt={5} mb={2}>
            <LoadingButton variant="gradient" color="info" onClick={handleSubmit}>
              Envoyer
            </LoadingButton>
          </Grid>
          {Object.keys(errors).length !== 0 && (
            <MKTypography variant="body2" color="error" align="center" mt={2}>
              Certaines informations sont incorrectes ou manquantes. Veuillez vérifier les champs en
              rouge et réessayer.
            </MKTypography>
          )}
        </form>
      </DefaultHeader>
    </SuspenseSpin>
  );
};

export default Sondage;
