import { articles, legaux, routes } from "data/data";
import { getImage } from "lib";

  export const getArticle = (name) => {
    return { ...articles, ...legaux }[name];
  };
  
  export const getArticleSquareImage = (name) => {
    return getImage({ ...articles, ...legaux }[name].image_c) || "...";
  };
  
  export const getArticleTitle = (t, name) => {
    return t(`articles.${name}.title`) || "...";
  };
  
  export const getArticleRoute = (name) => {
    // console.log("getArticleRoute>", routes)
    const foundInCollaspse = routes.filter(r => r.collapse).flatMap(route => route.collapse).find(item => item.name === name);
    const foundInRoot = routes.filter(r => !r.collapse).find(item => item.name === name);
    // console.log("getArticleRoute>>", name, foundInCollaspse, foundInRoot)
    return foundInCollaspse?.route || (foundInRoot?.route || "");
  };
    