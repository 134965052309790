/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import MailIcon from "@mui/icons-material/Mail";
import PhoneIcon from "@mui/icons-material/Phone";
import PlaceIcon from "@mui/icons-material/Place";
import { Container, Icon } from "@mui/material";
import Grid from "@mui/material/Grid";
import pxToRem from "assets/theme/functions/pxToRem";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import config from "config";
import { routes } from "data/data";
import MainFooter from "examples/Footers/MainFooter";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import { buildEmailLink, buildPhoneLink, getImage } from "lib";
import ContactForm from "pages/ContactUs/ContactForm";
import propTypes from "prop-types";

const backgroundImage = getImage(config.contactImg);

const Card = ({ mt, icon, children }) => (
  <Grid item xs={12} sm={6} md={6} lg={3}>
    <MKBox
      mt={mt}
      borderRadius="xxl"
      textAlign="center"
      sx={({ palette: { white }, functions: { rgba } }) => ({
        backgroundColor: rgba(white.main, 0.8),
        padding: "20px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        backdropFilter: `saturate(200%) blur(30px)`,
        minHeight: pxToRem(170), // Assure une hauteur minimale pour éviter des collisions
        display: "flex",
        flexDirection: "column",
        height: "100%", // Prend toute la hauteur disponible
      })}
    >
      <MKTypography variant="body2" fontWeight="bold" fontSize="2rem">
        {icon}
      </MKTypography>
      {/* <MKBox
        component="h3"
        sx={{
          fontSize: "1.25rem",
          fontWeight: "bold",
          marginBottom: "12px",
        }}
      >
        OUR MAIN OFFICE
      </MKBox> */}
      <MKBox
        component="p"
        sx={{
          fontSize: "1rem",
          color: "#555",
          wordBreak: "break-word", // Ajoute le retour à la ligne pour les mots longs
          overflowWrap: "break-word",
        }}
      >
        {children}
      </MKBox>
    </MKBox>
  </Grid>
);

Card.propTypes = {
  mt: propTypes.any.isRequired,
  icon: propTypes.node,
  children: propTypes.node,
};

function ContactUsWithData() {
  return (
    <>
      <DefaultNavbar routes={routes} sticky />
      <MKBox bgColor="white">
        <MKBox
          minHeight={"30rem"}
          width="100%"
          sx={{
            backgroundImage: `url(${backgroundImage})`, // Remplace avec ton image
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "flex", // Flexbox to center content
            alignItems: "center",
            justifyContent: "center",
          }}
        />
        <Container>
          <Grid
            container
            spacing={4} // Ajoute de l'espacement entre les cartes
            justifyContent="center"
            alignItems="center"
          >
            {/* Première carte */}
            <Card icon={<PlaceIcon />} mt={{ xs: -10, md: -10 }}>
              {config.address}
            </Card>

            {/* Deuxième carte */}
            <Card icon={<PhoneIcon />} mt={{ sm: -10, xs: 0, md: -10 }}>
              {buildPhoneLink(config.phoneNumber)}<br />
              {buildPhoneLink(config.natel1Number)}<br />
              {buildPhoneLink(config.natel2Number)}
            </Card>

            <Card icon={<MailIcon />} mt={{ xs: 0, md: 0, lg: -10 }}>
              {buildEmailLink(config.emailAdress)}
            </Card>

            {/* Bnaque */}
            <Card icon={<Icon>account_balance</Icon>} mt={{ xs: 0, md: 0, lg: -10 }}>
            IBAN : {config.iban}<br />
            N° de compte : {config.accountNumber}
            </Card>

          </Grid>
          <MKBox
            bgColor="white"
            borderRadius="xl"
            shadow="lg"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            mt={{ xs: 10, sm: 8, md: 10 }}
            mb={{ xs: 20, sm: 18, md: 20 }}
            mx={1}
          >
            <ContactForm avis={false} titleAlign="center"/>
          </MKBox>
        </Container>
        <MainFooter />
      </MKBox>
    </>
  );
}

export default ContactUsWithData;
