import { Grid } from "@mui/material";
import MKTypography from "components/MKTypography";
import propTypes from "prop-types";

const SectionTitle = ({ title }) => (
  <Grid container item justifyContent="center" xs={10} lg={7} mx="auto" textAlign="center">
    <MKTypography variant="h3" my={3}>
      {title}
    </MKTypography>
  </Grid>
);

SectionTitle.propTypes = {
  title: propTypes.string,
};

export default SectionTitle;