import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import { createAffiliateLink } from "lib";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const data = {
  Ledger: {
    url: "https://www.amazon.fr/dp/B07MBH6BPW",
    store: "Amazon",
    model: "Ledger Nano X"
  },
  LedgerS: {
    url: "https://www.amazon.fr/dp/B09W71RQWV",
    store: "Amazon",
    model: "Ledger Nano S Plus"
  },
  Coldcard: {
    url: "https://coldcard.com/",
    store: "Coldcard",
    affiliate: false,
    model: "Coldcard"
  },
  KeepKey: {
    url: "https://www.amazon.fr/dp/B0143M2A5S",
    store: "Amazon",
    model: "KeepKey"
  },
  TrezorT: {
    url: "https://www.amazon.fr/dp/B07B8Q2G3K",
    store: "Amazon",
    model: "Trezor Model T"
  },
  WhiteBookBitcoin: {
    url: "https://www.amazon.fr/dp/B0BKJ944WQ",
    store: "Amazon",
  },
};

function Buy({ name }) {
  const { t } = useTranslation();
  const store = data[name].store;
  const affiliate = data[name].affiliate ?? true;
  const model = data[name].model;
  const title = name === "WhiteBookBitcoin" ? t(`articles.buy.${name}`, { store }) : t(`articles.buy.wallet`, { model, store });
  const url = affiliate ? createAffiliateLink(data[name].url) : data[name].url;
  return (
    <MKBox mt={3} mb={4} textAlign="left">
      <MKButton
        color="custom"
        href={url}
        rel={`noopener noreferrer${affiliate ? ' sponsored' : ''}`}
        size="large"
        target="_blank"
        title={title}
        // onClick={() => handleButtonClick(action)}
      >
        {t("articles.buy.common", { store })}
      </MKButton>
      {affiliate && (
        <MKBox mt={1} fontSize="0.875rem" color="textSecondary">
          {t("affil.msg")}
        </MKBox>
      )}
    </MKBox>
  );
}

Buy.propTypes = {
  name: PropTypes.string.isRequired,
};

Buy.defaultProps = {
  simple: false,
};

export default Buy;
