import React, { useState } from "react";
import MKButton from "./MKButton"; // Assurez-vous que le chemin vers MKButton est correct
import CircularProgress from "@mui/material/CircularProgress";
import propTypes from "prop-types";

const LoadingButton = ({ onClick, children, ...props }) => {
    const [isLoading, setIsLoading] = useState(false);
  
    const handleClick = async (e) => {
      e.preventDefault(); // Ajout pour assurer que les formulaires fonctionnent correctement
  
      if (isLoading) return; // Empêche les clics multiples
  
      setIsLoading(true); // Désactive le bouton et affiche le spinner
      try {
        if (onClick) {
          await onClick(e); // Exécute la fonction fournie via `onClick`, en passant l'événement
        }
      } catch (error) {
        console.error("Une erreur s'est produite :", error);
      } finally {
        setIsLoading(false); // Réactive le bouton après l'action
      }
    };
  
    return (
      <MKButton
        variant="gradient"
        color="info"
        onClick={handleClick}
        disabled={isLoading} // Grise le bouton
        {...props}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {isLoading ? <CircularProgress size={19} color="inherit" /> : children}
      </MKButton>
    );
  };

LoadingButton.propTypes = {
  onClick: propTypes.func.isRequired,
  children: propTypes.node.isRequired,
};

export default LoadingButton;
