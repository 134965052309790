const siteConfig = {
  meilleurecrypto: {
    name: "La meilleure crypto",
    short_name: "MeilleureCrypto",
    theme_color: "#F5F5F5",
    emailAdress: "lameilleurecrypto@gmail.com",
    author: "delaunayj", //Julien Delaunay
    imagekitId: "qzjdsrhh6",
    captchaKey: "6LfwmUsqAAAAAGOAI-9G1_039C7fqgi2IBj8JkIG",
    gtmEnabled: true,
    gtmId: "GTM-5FXP7JGB",
    amazonId: "lameilleurecr-21",
    logo: "favicon.ico",
    customColor: "#f0b90b",
    home: "Presentation", 
    showDesciptionInHome: true,
    showAuthor: true,
    information: true,
    posts: true,
    testimonials: true,
    affiliate: true,
    contactImg: "digital.webp",
    avisImg: "digital.webp",
  },
  petitspas: {
    name: "La garderie aux petits pas",
    short_name: "AuxPetitsPas",
    theme_color: "#F5F5F5",
    emailAdress: "info@garderieauxpetitspas.ch",
    address: "La Mouniaz Centre, Route du Simplon 21, 1845 Noville",
    mapLink: "https://maps.app.goo.gl/yzQL3kjuBFcb2dPe9",
    phoneNumber: "+41 21 968 11 12",
    natel1Number: "+41 79 670 71 10",
    natel2Number: "+41 79 670 77 25",
    iban: "CH20 0900 0000 6523 8027 0",
    accountNumber: "65-238027-0",
    imagekitId: "o8gtczz93",
    captchaKey: "6LcO_mwqAAAAAOPhrF-6qcLBhfkrw2HMwfNnO1WC",
    logo: "logoc.ico",
    logoSize: "4rem",
    customColor: "#A7C7E7",
    home: "Presentation", 
    showSignupInHome: true,
    pages: true,
    details: true,
    team: true,
    netlifyFormEnabled: true,
    signupFormName: "inscription-en-ligne",
    contactImg: "batiment_5088px.webp",
    avisImg: "cottonbro.webp",
    displayContactData: true,
  },
  ouvertureuape: {
    name: "UAPE Pas à Pas",
    short_name: "PasAPas",
    theme_color: "#F5F5F5",
    emailAdress: "info@uape-pas-a-pas.ch",
    address: "canton de Vaud",
    imagekitId: "vqawfgkaq",
    captchaKey: "6LcZ54QqAAAAAApMOVkXLgJAUDjVOj2lZWwDgbE5",
    gtmEnabled: true,
    gtmId: "GTM-W63TZGZL",
    logo: "favicon3.ico",
    logoSize: "5rem",
    articleMinHeight: "35rem",
    customColor: "#A7C7E7",
    home: "Sondage", 
    sondageFormName: "sondage",
    contactImg: "pexels-yankrukov-8612995.webp",
    avisImg: "pexels-yankrukov-8612995.webp",
  },
  maxicashback: {
    name: "MaxiCashback",
    short_name: "MaxiCashback",
    theme_color: "#F5F5F5",
    emailAdress: "maxicashbackapp@gmail.com",
    imagekitId: "36w4u7tji",
    captchaKey: "6LcO_mwqAAAAAOPhrF-6qcLBhfkrw2HMwfNnO1WC",
    amazonId: "maxicashback-21",
    logo: "logot.png",
    customColor: "#f0b90b",
    home: "Presentation", 
    formAffiliate: true,
    pages: true,
    affiliate: true,
    contactImg: "pexels-mart-production-7718719.webp",
    avisImg: "pexels-mart-production-7718719.webp",
  },
};
module.exports = siteConfig[process.env.REACT_APP_SITE];